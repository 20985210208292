import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

export const siteMetaDataFragment = graphql`
  fragment SiteMetaDataFragment on Site {
    siteMetadata {
      siteName
      url
    }
  }
`

class RootIndex extends React.Component {
  render () {
    return (
      <ContentfulPage
        blocks={this.props.blocks}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Home`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}`}
        pageDescription='We are helping governments and businesses make good on the promise of healthcare in the fastest growing parts of the world. And it starts with access.'
      />
    )
  }
}

export default withContentfulBlocks(RootIndex)

export const pageQuery = graphql`
  query FIHomeQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "2H5JXBVzTbnIgsQ3eUQsNG" }) {
      ...PageBlocksFragment
    }
  }
`
